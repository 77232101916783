// Copyright 2021
// Polus
import React from 'react';

import CheckIcon from '../icons/check';
import { createUUID } from '../utils/numbers';

const FEATURES = [
  'Spaced-repetition algorithm',
  'Image support',
  'Works on desktop, iOS, iPad and Android',
  'No ads',
  'Powerful card types: reveal, type, invert prompt and answer',
  'Import existing decks from Anki, Quizlet and others',
];

const PREMIUM_FEATURES = ['Graded test mode', 'Rich text and Markdown cards'];

const PREMIUM_SUBSCRIPTION_PRICE_USD_LABEL = '$4';

const FeatureList = (): React.ReactElement => (
  <>
    <div className="flex flex-wrap rounded-t-lg bg-gradient-to-r from-indigo-400 to-blue-400 px-4 pt-4 pb-2 text-white shadow-lg">
      {FEATURES.map((feature) => (
        <div key={createUUID()} className="mb-1 flex h-full items-center md:w-1/2">
          <div className="mr-1 h-8 w-8 shrink-0 grow-0">
            <CheckIcon />
          </div>
          <p className="mb-0 ml-1 text-lg font-bold">{feature}</p>
        </div>
      ))}
    </div>
    <div className="flex flex-wrap rounded-b-lg bg-yellow-400 py-2 px-4 text-white shadow-lg">
      {PREMIUM_FEATURES.map((feature) => (
        <div key={createUUID()} className="mb-1 flex h-full items-center md:w-1/2">
          <div className="mr-1 h-8 w-8 shrink-0 grow-0">
            <CheckIcon />
          </div>
          <div className="ml-1">
            <p className="mb-0 inline align-middle text-lg font-bold">{feature}</p>
            <p className="ml-2 inline-block rounded bg-yellow-600 px-1 text-center align-middle text-sm font-semibold text-white">
              Premium
            </p>
          </div>
        </div>
      ))}
    </div>
    <p className="mt-4 w-full text-center text-sm text-gray-500">
      Studius Premium costs {PREMIUM_SUBSCRIPTION_PRICE_USD_LABEL} a month. Features without the
      Premium label are free.
    </p>
  </>
);

export default FeatureList;
