// Copyright 2021
// Polus

import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Banner from '../components/Banner';
import FeatureList from '../components/FeatureList';

const IndexPage = ({ location }: PageProps): React.ReactElement => (
  <Layout>
    <Seo title="Home" />
    <Banner />
    <div className="flex py-8 md:flex-row">
      <div>
        {/* TODO: move to separate component */}
        <div className="mb-4 flex flex-col-reverse items-center justify-around px-4 md:flex-row md:p-8">
          <div className="md:w-1/2">
            <p className="p-4">
              <b>Studius is the best app for creating and studying flashcards</b>. It uses a smart
              algorithm to minimize your time spent studying. So you can focus on what matters, when
              it matters most.
            </p>
          </div>
          <StaticImage
            alt="Screenshot"
            className="rounded-md border-2 shadow-sm md:w-1/3"
            quality={100}
            src="../images/screenshot.png"
          />
        </div>

        <div className="mb-4 flex flex-col items-center justify-around px-4 md:flex-row md:p-8">
          <StaticImage
            alt="Ebbinghaus Curve"
            className="rounded-md border-2 shadow-sm md:w-2/5"
            quality={95}
            src="../images/ebbinghaus-curve.png"
          />
          <div className="md:w-1/2">
            <p className="p-4">
              <b>Fetching information from long term memory is the best way to learn</b>.
              Information needs to be right between your short term memory and being forgotten.
              Recall it at that stage – and you reinforce it in your long term memory.
            </p>
          </div>
        </div>
      </div>
    </div>
    <FeatureList />
    <a href={`https://app.studius.ai${location.search}`}>
      <p className="mt-8 text-center text-gray-500 hover:underline">
        Thousands of students are using Studius every day. <b>Join them now.</b>
      </p>
    </a>
  </Layout>
);

export default IndexPage;
